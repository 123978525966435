@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
    @tailwind base;
}

@layer tailwind-utilities {
    @tailwind components;
    @tailwind utilities;
}

html{
    @apply scrollbar-track-brandGray-6 scrollbar-thumb-secondary-light;
}

body, .root{
    @apply min-h-screen;
}

.root{
    @apply flex flex-col;
}